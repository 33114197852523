<template>
  <div>
    <search-field title="条件筛选" :showField="true">
      <el-form :inline="true" size="small" label-width="60px">
        <el-form-item label="标题" >
          <el-input clearable v-model="search.searchFields.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="状态" >
          <el-select v-model="search.searchFields.status">
            <el-option
              v-for="item in statusOptions"
              :key="item.code"
              :label="item.value"
              :value="item.code"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="BA">
          <el-select v-model="search.searchFields.ba_id" placeholder="请选择BA">
            <el-option
              v-for="item in BAList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getResourceList(1)">查询</el-button>
        <el-button type="primary" size="small"  @click="clearSearch()">重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
      </div>
      <v-table
        :data="options.data"
        :columns="options.columns"
        :isBackPage="true"
        :totalCount="options.totalCount"
        :defaultcurrentPage="search.searchFields.page"
        @handleCurrentChange="handlePageChange"
        @handleSizeChange="handleSizeChange"
        @buttonClick="handleButtonClick"
      >
      </v-table>
    </div>
    <el-dialog
      :title="modalTitle"
      width="65%"
      :visible.sync="resourceAddDialog"
      >
        <el-form size="small" label-width="130px">
          <el-form-item
            label="标题"
            required
            :error="errorBags.collect('标题')[0]"
          >
            <el-input
              v-model="resourceForm.title"
              placeholder="请输入标题"
              v-validate="'required'"
              data-vv-name="标题"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="简要说明"
            required
            :error="errorBags.collect('简要说明')[0]"
          >
            <el-input
              type="tel"
              v-model="resourceForm.content"
              placeholder="请输入简要说明"
              v-validate="'required'"
              data-vv-name="简要说明"
            ></el-input>
          </el-form-item>
          <el-form-item
              label="获取积分"
              required
              :error="errorBags.collect('获取积分')[0]"
          >
            <el-input
                type="tel"
                v-model="resourceForm.prize_num"
                maxlength="12"
                placeholder="请输入获取积分"
                v-validate="'required'"
                data-vv-name="获取积分"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" required :error="errorBags.collect('状态')[0]">
            <el-select v-model="resourceForm.status" v-validate="'required'" data-vv-name="状态">
              <el-option
                  v-for="item in statusOptions"
                  :key="item.code"
                  :label="item.value"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div style="padding-left: 130px;">
            <p style="color: #bbb; margin: 12px 0;">
              仅限{{ accept }}格式
            </p>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 12px;">
            <h4 style="width: 130px; text-align: right;">学习资料：</h4>
            <ali-uploader
                :limitSize="limitSize"
                :autoUpload="false"
                :uploadFolder="false"
                :showUploadBtn="false"
                :showFileList="true"
                :multiple="false"
                :limit="1"
                :accept="accept"
                uploadRef="receipt"
                ref="receipt"
                @getDir="getDir"
                @beforeUpload="beforeUpload"
                @handleUploadSuccess="handleUploadSuccess"
                @handleChange="handleChange"
                @getUploadUrl="url => (resourceForm.url = url)"
            ></ali-uploader>
          </div>
          <div v-if="oldFileUrl && editResourceId" style="padding-left: 130px;margin: 12px 0 12px;height: 550px; overflow-y: auto;">
            <vue-office-docx v-if="['doc', 'docx'].includes(resourceForm.type)" :src="oldFileUrl"  />
            <img v-if="['jpeg', 'jpg', 'png'].includes(resourceForm.type)" :src="oldFileUrl" alt="" />
            <pdf-viewer v-show="['pdf'].includes(resourceForm.type)" :src="oldFileUrl" v-for="i in numPages" :key="i" :page="i"></pdf-viewer>
            <video v-if="['mp4'].includes(resourceForm.type)" :src="oldFileUrl" controls="controls" playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint"></video>
            <audio v-if="['mp3'].includes(resourceForm.type)" :src="oldFileUrl" controls></audio>
          </div>
          <el-form-item
              v-if="['doc', 'docx', 'jpeg', 'jpg', 'png', 'pdf'].includes(resourceForm.type) || ['doc', 'docx', 'jpeg', 'jpg', 'png', 'pdf'].includes(resourceForm.url.split('.').pop())"
              label="最短学习时长/秒"
              required
              :error="errorBags.collect('最短学习时长/秒')[0]"
          >
            <el-input
                type="tel"
                v-model="resourceForm.threshold"
                placeholder="请输入最短学习时长/秒"
                v-validate="'required'"
                data-vv-name="最短学习时长/秒"
            >
              <template slot="append">秒</template>
            </el-input>
          </el-form-item>
          <div class="btn-container">
            <el-button @click="closeModal">取消</el-button>
            <el-button type="primary" @click="handleSubmit">确定</el-button>
          </div>
        </el-form>
    </el-dialog>
    <el-dialog
      :title="modalTitle"
      width="65%"
      :visible.sync="resourceDetailDialog"
      >
        <el-descriptions :column="1">
          <el-descriptions-item label="标题">{{ resourceForm.title }}</el-descriptions-item>
          <el-descriptions-item label="简要说明">{{ resourceForm.content }}</el-descriptions-item>
          <el-descriptions-item label="获取积分">{{ resourceForm.prize_num }}</el-descriptions-item>
          <el-descriptions-item v-if="['doc', 'docx', 'jpeg', 'jpg', 'png'].includes(resourceForm.type)" label="最短学习时长/秒">{{ resourceForm.threshold }}秒</el-descriptions-item>
          <el-descriptions-item label="状态">{{ resourceForm.status === '1' ? '上架' : '下架'}}</el-descriptions-item>
        </el-descriptions>
        <div style="height: 660px; overflow-y: auto;">
          <h4 style="margin-bottom: 15px;">学习资料: </h4>
          <vue-office-docx v-if="['doc', 'docx'].includes(resourceForm.type)" :src="resourceForm.url" />
          <img v-if="['jpeg', 'jpg', 'png'].includes(resourceForm.type)" :src="resourceForm.url" alt="" />
          <pdf-viewer
            ref="pdf"
            v-show="['pdf'].includes(resourceForm.type) && resourceForm.url"
            style="display: inline-block; width:100%"
            id="pdfID"
            :src="oldFileUrl"
            v-for="i in numPages"
            :key="i"
            :page="i"></pdf-viewer>
          <video v-if="['mp4'].includes(resourceForm.type)" :src="resourceForm.url" controls="controls" playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint"></video>
          <audio v-if="['mp3'].includes(resourceForm.type)" :src="resourceForm.url" controls></audio>
        </div>
      </el-dialog>
  </div>
  </template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import { BAApi } from '@/api'
import { formatDate } from '@/libs/utils'
import AliUploader from '@/components/tools/aliUploader'
import simpleOssUpload from '@/mixins/simpleOssUploadMixin'
import PdfViewer from 'vue-pdf'
import VueOfficeDocx from '@vue-office/docx'

export default {
  mixins: [simpleOssUpload],
  data() {
    return {
      options: {
        columns: [
          {
            label: '标题',
            key: 'title'
          },
          {
            label: '简要说明',
            key: 'content'
          },
          {
            label: '上传时间',
            type: 'render',
            render: (h, { row }) => {
              return (
                <div>
                  <div class="status-td">
                    {
                      <div>{formatDate(Number(row.create_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                    }
                  </div>
                </div>
              )
            }
          },
          {
            label: '获取积分',
            key: 'prize_num'
          },
          {
            label: 'BA总数',
            key: 'balm_sum'
          },
          {
            label: '完成学习BA',
            key: 'balm_num'
          },
          {
            label: '操作',
            type: 'action',
            selectButton: true,
            width: 220,
            buttonInfos: [
              {
                name: 'edit',
                label: '编辑',
                color: 'primary'
              },
              {
                name: 'detail',
                label: '详情',
                color: 'success'
              },
              {
                name: 'download',
                label: '下载',
                color: 'success'
              },
            ]
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          title: '',
          status: '',
          ba_id: ''
        }
      },
      statusOptions: [
        {
          code: '1',
          value: '上架'
        },{
          code: '0',
          value: '下架'
        },
      ],
      resourceAddDialog: false,
      modalTitle: '',
      editResourceId: '',
      resourceForm: {
        title: '',
        content: '',
        prize_num: '',
        url: '',
        threshold:'60',
        status: '1'
      },
      limitSize: '100',
      accept: 'image/jpeg,image/jpg,image/png,audio/mp3,video/mp4,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf',
      BAList: [],
      oldFileUrl: '',
      resourceDetailDialog: false,
      type: '',
      numPages: undefined
    }
  },
  methods: {
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getGoodsList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getGoodsList(1)
    },
    async getResourceList(page) {
      let loading = this.$loading()
      this.search.searchFields.page = page || this.search.searchFields.page
      try {
        let data = (await BAApi.getResourceList(this.search.searchFields)).data
        if (data.code === 0) {
          this.$validator.reset()
          const sum = data.data.baNum
          this.options.data = data.data.lms.map(item => {
            item.btnList = ['edit', 'detail', 'download']
            item.balm_sum = sum
            return item
          })
          this.options.totalCount = parseInt(data.total_count) || 0
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    async loadAndPreviewWord(file) {
      try {
        const result = await mammoth.convertToHtml({ path: file.path });
        this.docContent = result.value; // 将转换后的HTML插入到页面中
      } catch (err) {
        console.error(err);
      }
    },
    async getInfo(id, button) {
      let loading = this.$loading()
      try {
        let data = (await BAApi.getResourceInfo({
          id
        })).data
        if (data.code === 0) {
          this.resourceForm = Object.assign(this.resourceForm, data.data.lm)
          this.oldFileUrl = data.data.lm.url
          this.getPdfPages()
          this.type = data.data.lm.type
          const typeArr = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
          if (typeArr.includes(this.type) && (button === 'detail'||button === 'edit')) {
            this.loadAndPreviewWord(data.data.lm.url);
          }
          if (button === 'download') {
            const url = data.data.lm.url
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `${data.data.lm.title}`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    getPdfPages() {
      let loading = this.$loading()
      const pdfUrl = this.oldFileUrl
      console.log(PdfViewer.createLoadingTask(pdfUrl).promise)
      PdfViewer.createLoadingTask(pdfUrl).promise.then(pdf => {
        this.numPages= pdf.numPages
        loading.close();//关闭loading
      }).catch(err => {
        loading.close();//关闭loading
      })
    },
    handleButtonClick(value) {
      this.numPages= null
      let type = value.button,
        data = value.data;
        this.editResourceId = data.id;
        this.getInfo(this.editResourceId, type)
      if (type === 'edit') {
        this.resourceAddDialog = true
        this.modalTitle = '编辑学习资料'
        this.$nextTick(() => {
          this.$refs.receipt && this.$refs.receipt.clearFiles()
        })
      } else if(type === 'detail') {
        this.resourceDetailDialog = true
        this.modalTitle = '学习资料'
        this.$nextTick(() => {
          this.$refs.receipt && this.$refs.receipt.clearFiles()
        })
      }

    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({ count: this.search.searchFields.count }, {
          page: 1,
          title: '',
          status: '',
          ba_id: ''
        })
      }
    },
    handleAdd() {
      this.resourceAddDialog = true
      this.modalTitle = '新增学习资料'
      this.resetForm()
      this.$nextTick(() => {
        this.$refs.receipt && this.$refs.receipt.clearFiles()
      })

    },
    closeModal() {
      this.resetForm()
      this.$validator.reset()
      this.resourceAddDialog = false
      this.oldFileUrl = ''
    },
    resetForm() {
      let obj = {
        title: '',
        content: '',
        prize_num: '',
        url: '',
        threshold:'60',
        status: '1'
      }
      this.resourceForm = obj
      this.editResourceId = '';
      this.oldFileUrl = ''
      this.type = ''
      this.$validator.reset()
    },
    async addResource() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.resourceForm,
          id: this.editResourceId
        }
        if(!this.editResourceId) {
          let type = this.resourceForm.url.split('.')[this.resourceForm.url.split('.').length -1]
          params.type = type.split('?')[0]
        }
        if(this.editResourceId) {
          params.url = this.resourceForm.url.split("?")[0].split('com/').pop()
          params.type = this.resourceForm.url.split("?")[0].split(".")[this.resourceForm.url.split("?")[0].split(".").length - 1]
        }
        let data = (await BAApi.addOrEditResource(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.$message.success('添加资料成功')
          this.resetForm()
          this.resourceAddDialog = false
          this.getResourceList()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },
    handleSubmit() {
      this.$validator.validate().then(status => {
        if (status && this.resourceForm.url) {
          this.addResource()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    // BA
    async getBAList() {
      try {
        let data = (await BAApi.getBaList({
          check_status: 2
        })).data
        if (data.code === 0) {
          this.BAList = data.data.apply_list
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      }
    },
  },

  mounted() {
    this.thisImageFolder = '0'
    this.getBAList()
    this.getResourceList()
  },
  components: {
    vTable, searchField, AliUploader, PdfViewer, VueOfficeDocx
  }
}
</script>

