import md5 from 'md5'
import { authApi } from '../api'

export default {
  data: () => ({
    url: '', // 上传前附带数据url
    selectButtonDisable: false,
    imageFolders: {
      '0': 'referral', // 小票,
    },
    thisImageFolder: '',
    dir: '', //
    imageUrl: '',
    audioUrl: '',
    imgAccept: 'image/jpeg,image/jpg,application/x-jpg,application/x-png,image/png,image/gif,application/x-gif,',
    audioAccept: 'audio/mp3',
    xlsAccept:'xls,xlsx,csv',
    uploaded_url: '', // 上传后url
    uploadData: {
      key: '',
      policy: '',
      OSSAccessKeyId: '',
      success_action_status: 200,
      signature: '',
      name: '',
      file: ''
    },
    signature: '' // 签名
  }),
  methods: {
    beforeUpload({ file, fileList }) {
      file['relative_url'] = this.url
    },
    handleChange({ file, fileList, ref }, key) {
      let ext = file.name.split('.')[file.name.split('.').length - 1];
      let name = md5(`${file.uid}${file.name}`);
      this.url = `/${this.imageFolders[this.thisImageFolder]}/${name}.${ext}`;
      console.log(this.url, 9999)
      if (typeof key !== 'undefined') {
        this.$refs[ref][key].submitUpload(this.url)
      } else {
        this.$refs[ref].submitUpload(this.url)
      }
    },
    handleUploadSuccess({ file, fileList, ref }) {
      if (file.raw) {
        this.imageUrl = URL.createObjectURL(file.raw)
      } else {
        this.$message({
          message: '浏览器限制，无法预览图片',
          type: 'error'
        })
      }
      this.uploaded_url = `${this.dir}${this.url}`
      // this.$refs[ref].clearFiles()
    },
    handleFailed() {
      console.log('Failed to upload')
    },
    getDir(val) {
      this.dir = val
    }
  }
}
