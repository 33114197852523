<template>
  <div>
    <el-upload
      v-if="(!imageUrl && !audioUrl) || isPicList"
      v-loading="loading"
      :class="aliUploaderClass"
      v-bind:on-change="handleChange"
      v-bind:before-upload="beforeUpload"
      :auto-upload="autoUpload"
      :show-file-list="showFileList"
      :upload-folder="uploadFolder"
      :multiple="multiple"
      :data="uploadData"
      :accept="accept"
      :limit="limit"
      ref="refUploader"
      list-type="text"
      :file-list="filesToUpload"
      :disabled="selectButtonDisable"
      :action="actionUrl"
      :hidedel="hidedel"
      :on-remove="removeFile"
    >
      <el-button
        v-if="!autoUpload && fileList.length < limit"
        slot="trigger"
        size="small"
        :disabled="selectButtonDisable"
        type="primary"
      >
        {{
          selectButtonText
            ? selectButtonText
            : uploadFolder
            ? '选择文件夹'
            : '选择文件'
        }}
      </el-button>
      <el-button
        v-if="showUploadBtn"
        size="small"
        style="margin-left: 1em"
        type="primary"
        :disabled="uploadButtonDisable"
        @click="submitClick"
      >
        {{ uploadButtonText ? uploadButtonText : '上传到服务器' }}
      </el-button>
    </el-upload>
    <div v-if="!isAudio && imageUrl && !isPicList" class="preview-img">
      <img :src="imageUrl" height="260px" @click="handlePreview" />
      <i class="el-icon-remove" v-show="!hidedel" @click="deletePrev"></i>
    </div>
    <div v-if="isAudio && audioUrl" class="preview-img preview-audio">
      <el-button v-if="audioUrl" type="success" @click="playAudio(audioUrl)">
        播放音频
      </el-button>
      <i class="el-icon-remove" @click="deleteAudio"></i>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="imageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { authApi } from '../../api'

export default {
  name: 'AliUploader',
  props: {
    accept: {
      type: String,
      default: '*'
    },
    aliUploaderClass: {
      type: String,
      default: 'ali-uploader-class'
    },
    uploadFolder: {
      type: Boolean,
      default: false
    },
    prefixFolder: {
      type: String,
      default: ''
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    showUploadBtn: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    },
    selectButtonText: {
      type: String
    },
    uploadButtonText: {
      type: String,
      default: '上传文件'
    },
    selectButtonDisable: {
      type: Boolean,
      default: false
    },
    uploadRef: {
      type: String,
      default: 'aliUploader'
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    isAudio: {
      type: Boolean,
      default: false
    },
    previewImg: {
      type: String,
      default: ''
    },
    previewAudio: {
      type: String,
      default: ''
    },
    prevPlayEle: {},
    hidePreview: {
      type: Boolean,
      default: false
    },
    folderName: {
      type: String,
      default: ''
    },
    limitSize: {
      type: String,
      default: ''
    },
    hidedel: {
      type: Boolean,
      default: false
    },
    isPicList: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  components: {},
  data() {
    return {
      uploadButtonDisable: true,
      actionUrl: this.action,
      fileList: [],
      prefixUrl: this.prefixFolder,
      signature: {},
      imageUrl: '',
      uploadData: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: 200,
        signature: '',
        name: '',
        callback: '' // 用于获取阿里云上传成功与否的信息
      },
      tempUrl: '', // 图片生成路径
      uploaded_url: '', // 上传后路径
      dialogVisible: false,
      loading: false,
      audioUrl: '',
      curPlayEle: '', // 播放对象
      resetUploaded_url: false
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList
      let event = 'handleChange'
      if (file.status === 'ready') {
        event = 'handleChange'
      } else if (file.status === 'fail') {
        event = 'handleFailed'
        this.loading = false
      } else if (file.status === 'success') {
        this.loading = false
        if (file.response.code) {
          this.$message.error('上传失败')
          return
        }
        event = 'handleUploadSuccess'
        if (this.showPreview) {
          if (file.raw) {
            if (this.isAudio) {
              this.audioUrl = URL.createObjectURL(file.raw)
            } else {
              this.imageUrl = URL.createObjectURL(file.raw)
            }
          } else {
            this.$message({
              message: '浏览器限制，无法预览图片或者音频',
              type: 'error'
            })
          }
          this.uploaded_url = `${this.signature.dir}${this.tempUrl}`
          this.clearFiles()
        } else if (this.isPicList) {
          if (file.raw) {
            this.imageUrl = URL.createObjectURL(file.raw)
          }
          this.uploaded_url = `${this.signature.dir}${this.tempUrl}`
        } else {
          this.uploaded_url = `${this.signature.dir}${this.tempUrl}`
        }
      }
      if (file.status === 'ready' && this.uploadFolder) {
        let lsize = fileList.length
        let that = this
        setTimeout(() => {
          if (lsize === that.fileList.length) {
            that.$emit(event, {
              file,
              fileList,
              ref: this.uploadRef
            })
            that.uploadButtonDisable = false
          }
        }, 100)
      } else {
        let fileSize = file.size / 1024 / 1024
        if (
          file.status === 'ready' &&
          this.limitSize &&
          fileSize > this.limitSize / 1
        ) {
          this.$message.error(`上传图片大小不能超过${this.limitSize}M`)
          this.deletePrev()
          return
        }
        this.$emit(event, {
          file,
          fileList,
          ref: this.uploadRef
        })
      }
    },
    beforeUpload(file, fileList) {
      this.$emit('beforeUpload', {
        file,
        fileList
      })
      if (this.uploadFolder && !file['relative_url']) {
        return false
      }
      let relativePath = file['relative_url']
        ? file['relative_url']
        : file.webkitRelativePath
      let [fileName] = file.name.split('.')
      if (relativePath === '') {
        relativePath = file.name
      }
      this.uploadData.key = this.signature.dir + relativePath
      this.uploadData.name = fileName
    },
    submitClick() {
      this.$emit('submitClick', {})
    },
    submitUpload(tempUrl) {
      this.loading = true
      this.tempUrl = tempUrl
      this.uploadButtonDisable = true
      let now = Date.parse(new Date()) / 1000
      if (
        this.signature == null ||
        this.signature.expire < now + 20 ||
        this.signature.dir !== this.prefixUrl
      ) {
        let that = this
        Promise.all([this.getOssSignature()]).then(() => {
          that.$refs.refUploader.submit()
        })
      } else {
        this.$refs.refUploader.submit()
      }
    },
    async getOssSignature() {
      const params = {
        upload_prefix: this.prefixUrl
      }
      console.log(this.folderName, 333333)
      if (this.folderName) {
        params.type = this.folderName
      }
      try {
        let data = (await authApi.getAliOssSignature(params)).data
        if (data.code && data.code !== 0) {
          this.$error(data)
          return
        }
        this.signature = data
        this.uploadData = {
          policy: this.signature.policy,
          OSSAccessKeyId: this.signature.accessid,
          signature: this.signature.signature,
          success_action_status: 200,
          callback: this.signature.callback
        }
        this.$emit('getDir', this.signature.dir)
        this.actionUrl = location.protocol + '//' + data.host
      } catch (e) {
        console.error(e)
      }
    },
    changeFileListFolder(folder) {
      this.fileList.forEach((v, i) => {
        v.name =
          folder + v.name.slice(v.name.lastIndexOf('/') - v.name.length + 1)
      })
    },
    clearFiles() {
      this.fileList = []
      this.uploadButtonDisable = true
    },
    deletePrev() {
      this.imageUrl = ''
      this.uploaded_url = ''
      this.resetUploaded_url = true
      this.clearFiles()
    },
    handlePreview() {
      this.dialogVisible = true
    },
    playAudio(audioUrl) {
      if (this.curPlayEle) {
        this.curPlayEle.pause()
      }
      this.curPlayEle = new Audio(audioUrl)
      this.curPlayEle.oncanplay = () => {
        this.curPlayEle.play()
        this.$emit('handlePlay', this.curPlayEle)
      }
    },
    deleteAudio() {
      this.audioUrl = ''
      this.uploaded_url = ''
      this.resetUploaded_url = true
      if (this.curPlayEle) {
        this.curPlayEle.pause()
      }
      this.curPlayEle = null
    },
    removeFile(file, fileLis) {
      this.fileList = fileLis
    }
  },
  mounted() {
    if (this.uploadFolder) {
      document
        .querySelector('.' + this.aliUploaderClass + ' .el-upload__input')
        .setAttribute('webkitdirectory', 'webkitdirectory')
    }
  },
  computed: {
    filesToUpload() {
      return this.fileList
    }
  },
  watch: {
    imageUrl(val) {
      this.$emit('showImg', val)
    },
    uploaded_url(newVal) {
      let url = this.imageUrl
      if (this.isAudio) {
        url = this.audioUrl
      }
      console.log(this.imageUrl, newVal, 'in')
      this.$emit('getUploadUrl', newVal, url)
    },
    resetUploaded_url() {
      this.$emit('getUploadUrl', '', '')
    },
    previewImg: {
      handler: function (newVal, oldVal) {
        this.imageUrl = newVal
        console.log(newVal, oldVal, 6666666)
      },
      immediate: true
    },
    previewAudio(newVal) {
      this.audioUrl = newVal
    },
    prevPlayEle(newVal) {
      this.curPlayEle = newVal
    },
    hidePreview(newVal) {
      if (newVal) {
        this.imageUrl = ''
        this.audioUrl = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.preview-img {
  position: relative;
  display: inline-block;
  height: 260px;
  i {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 26px;
    color: gray;
  }
}
.preview-audio {
  width: 80px;
  i {
    right: -10px;
    top: -10px;
    font-size: 24px;
  }
}
</style>
